import React from "react";

export default function PrivacyPolicyDescription() {
  return (
    <div className="container my-5">
      <div className="title-holder text-center mb-4">
        <h3>Politique de Confidentialité</h3>
        <div className="subtitle">
          Veuillez lire attentivement notre politique concernant la collecte et
          l'utilisation de vos données personnelles.
        </div>
      </div>
      <div>
        <h4>1. Objet de la présente charte</h4>
        <p>
          La présente politique de confidentialité a pour but d’informer les
          utilisateurs du site :
          <ul>
            <li>
              Sur la manière dont sont collectées leurs données personnelles.
            </li>
            <li>
              Sont considérées comme des données personnelles, toute information
              permettant d’identifier un utilisateur. A ce titre, il peut s’agir
              : de ses noms et prénoms, de son adresse postale, email ou de son
              numéro de téléphone;
            </li>
            <li>
              Sur la personne responsable du traitement des données à caractère
              personnel collectées et traitées;
            </li>
            <li>Sur les droits dont ils disposent concernant ces données;</li>
            <li>Sur les destinataires de ces données personnelles;</li>
            <li>Sur la politique du site en matière de cookies.</li>
          </ul>
        </p>
        <p>
          Cette politique complète les mentions légales et les Conditions
          Générales d’Utilisation consultables par les utilisateurs à l’adresse
          suivante :{" "}
          <a href="/terms-of-service">Conditions Générales d'Utilisation</a>
        </p>
        <p>
          Osly se conforme à la législation en vigueur en matière de protection
          de la vie privée et des données personnelles et notamment le règlement
          européen 2016/679 du 27 avril 2016 (ci-après: le « RGPD ») et la loi
          n°2018-493 du 20 juin 2018 relative à la protection des données
          personnelles.
        </p>

        <h4>
          2. Principes relatifs à la collecte et au traitement des données
          personnelles
        </h4>
        <p>
          Conformément à l’article 5 du Règlement européen 2016/679, les données
          à caractère personnel sont :
          <ul>
            <li>
              Traitées de manière licite, loyale et transparente au regard de la
              personne concernée;
            </li>
            <li>
              Collectées pour des finalités déterminées (cf. Article 3.1 des
              présentes), explicites et légitimes, et ne pas être traitées
              ultérieurement d'une manière incompatible avec ces finalités;
            </li>
            <li>
              Adéquates, pertinentes et limitées à ce qui est nécessaire au
              regard des finalités pour lesquelles elles sont traitées;
            </li>
            <li>
              Exactes et, si nécessaire, tenues à jour. Toutes les mesures
              raisonnables doivent être prises pour que les données à caractère
              personnel qui sont inexactes, eu égard aux finalités pour
              lesquelles elles sont traitées, soient effacées ou rectifiées sans
              tarder;
            </li>
            <li>
              Conservées sous une forme permettant l'identification des
              personnes concernées pendant une durée n'excédant pas celle
              nécessaire au regard des finalités pour lesquelles elles sont
              traitées;
            </li>
            <li>
              Traitées de façon à garantir une sécurité appropriée des données
              collectées, y compris la protection contre le traitement non
              autorisé ou illicite et contre la perte, la destruction ou les
              dégâts d'origine accidentelle, à l'aide de mesures techniques ou
              organisationnelles appropriées.
            </li>
          </ul>
        </p>
        <p>
          Le traitement n'est licite que si, et dans la mesure où, au moins une
          des conditions suivantes est remplie :
          <ul>
            <li>
              La personne concernée a consenti au traitement de ses données à
              caractère personnel pour une ou plusieurs finalités spécifiques;
            </li>
            <li>
              Le traitement est nécessaire à l'exécution d'un contrat auquel la
              personne concernée est partie ou à l'exécution de mesures
              précontractuelles prises à la demande de celle-ci;
            </li>
            <li>
              Le traitement est nécessaire au respect d'une obligation légale à
              laquelle le responsable du traitement est soumis;
            </li>
            <li>
              Le traitement est nécessaire à la sauvegarde des intérêts vitaux
              de la personne concernée ou d'une autre personne physique;
            </li>
            <li>
              Le traitement est nécessaire à l'exécution d'une mission d'intérêt
              public ou relevant de l'exercice de l'autorité publique dont est
              investi le responsable du traitement;
            </li>
            <li>
              Le traitement est nécessaire aux fins des intérêts légitimes
              poursuivis par le responsable du traitement ou par un tiers, à
              moins que ne prévalent les intérêts ou les libertés et droits
              fondamentaux de la personne concernée qui exigent une protection
              des données à caractère personnel, notamment lorsque la personne
              concernée est un enfant.
            </li>
          </ul>
        </p>

        <h4>3. Responsable du traitement des données</h4>
        <p>
          Responsable de la collecte de Données : Les données à caractère
          personnelles sont collectées par Osly Solutions, société par action
          simplifiée, dont le numéro d’immatriculation est le 97752473500015. Le
          responsable du traitement des données à caractère personnel peut être
          contacté de la manière suivante :
        </p>
        <p>
          Par courrier à l’adresse : 27 route du Muguet, 44450
          Saint-Julien-De-Concelles; Par mail : armandpetit.osly@gmail.com.
        </p>

        <h4>4. Pilote de la protection des données</h4>
        <p>
          Le pilote de la protection des données de l’entreprise est : Armand
          Petit, 27 route du Muguet, 44450 Saint-Julien-De-Concelles,
          armandpetit.osly@gmail.com
        </p>

        <h4>
          5. Données à caractère personnel collectées et traitées dans le cadre
          de la navigation sur le site
        </h4>
        <h5>5.1 Données collectées</h5>
        <p>
          Les données personnelles collectées dans le cadre de notre activité
          sont les suivantes : nom, prénom, adresse e-mail, numéro de téléphone.
        </p>
        <p>
          La collecte et le traitement de ces données répond aux finalités
          suivantes :
          <ul>
            <li>Gestion de votre accès à nos Services,</li>
            <li>Gestion des clients concernant les contrats,</li>
            <li>
              Mise en place des statistiques de fréquentation de nos Services,
            </li>
            <li>
              Gérer la gestion des avis des personnes sur des produits, services
              ou contenus,
            </li>
            <li>Personnaliser les réponses à vos demandes d’information,</li>
            <li>Respecter nos obligations légales et réglementaires.</li>
          </ul>
        </p>

        <h5>5.2 Mode de collecte</h5>
        <p>
          Vos données sont collectées:
          <ul>
            <li>Lors de l’inscription sur l’application mobile</li>
            <li>
              Par l’intermédiaire de prestataires externes, avec votre accord
              préalable.
            </li>
          </ul>
        </p>
        <p>
          Elles sont conservées par le responsable du traitement dans des
          conditions raisonnables de sécurité, lorsque le contrat est actif et
          supprimé dès la clôture de celui-ci. Osly Solutions est susceptible de
          conserver certaines données à caractère personnel au-delà des délais
          annoncés ci-dessus afin de remplir ses obligations légales ou
          réglementaires.
        </p>

        <h4>6. Hébergement de vos Données</h4>
        <p>
          L'hébergeur du Site est la société Bubble Group, Inc., dont le siège
          social est situé au 22 W 21st Street 2nd Floor New York, NY 10010.
        </p>

        <h4>7. La cession de vos Données</h4>
        <p>
          Vos Données ne feront pas l’objet de cessions, locations ou échanges
          au bénéfice de tiers.
        </p>

        <h4>
          8. Les droits de l’utilisateur en matière de collecte et de traitement
          des données
        </h4>
        <p>
          Tout utilisateur concerné par le traitement de ses données
          personnelles peut se prévaloir des droits suivants, en application du
          règlement européen 2016/679 et de la Loi Informatique et Liberté (Loi
          78-17 du 6 janvier 1978) :
          <ul>
            <li>
              Droit d’accès, de rectification et droit à l’effacement des
              données (posés respectivement aux articles 15, 16 et 17 du RGPD);
            </li>
            <li>Droit à la portabilité des données (article 20 du RGPD);</li>
            <li>
              Droit à la limitation (article 18 du RGPD) et à l’opposition du
              traitement des données (article 21 du RGPD);
            </li>
            <li>
              Droit de ne pas faire l’objet d’une décision fondée exclusivement
              sur un procédé automatisé;
            </li>
            <li>Droit de déterminer le sort des données après la mort;</li>
            <li>
              Droit de saisir l’autorité de contrôle compétente (article 77 du
              RGPD).
            </li>
          </ul>
        </p>
        <p>
          Pour exercer vos droits, veuillez vous adresser à Armand Petit par
          mail à l’adresse électronique armandpetit.osly@gmail.com. Afin que le
          responsable du traitement des données puisse faire droit à sa demande,
          l’utilisateur peut être tenu de lui communiquer certaines informations
          telles que : ses noms et prénoms, son adresse e-mail ainsi que son
          numéro de compte, d’espace personnel ou d’abonné. Consultez le site{" "}
          <a href="https://www.cnil.fr">cnil.fr</a> pour plus d’informations sur
          vos droits.
        </p>

        <h4>
          9. Conditions de modification de la politique de confidentialité
        </h4>
        <p>
          L’éditeur du site Osly Solutions se réserve le droit de pouvoir
          modifier la présente Politique à tout moment afin d’assurer aux
          utilisateurs du site sa conformité avec le droit en vigueur. Les
          éventuelles modifications ne sauraient avoir d’incidence sur les
          achats antérieurement effectués sur le site, lesquels restent soumis à
          la Politique en vigueur au moment de l’achat et telle qu’acceptée par
          l’utilisateur lors de la validation de l’achat. L’utilisateur est
          invité à prendre connaissance de cette Politique à chaque fois qu’il
          utilise nos services, sans qu’il soit nécessaire de l’en prévenir
          formellement.
        </p>
        <p>
          La présente politique, éditée le 01/12/2022 a été mise à jour le
          26/06/2023.
        </p>
      </div>
    </div>
  );
}
