import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Gestionnaire/Hero";
import Workflow from "../components/Gestionnaire/Workflow";
import Api from "../components/Gestionnaire/Api";
import WebApp from "../components/Gestionnaire/WebApp";
import Device from "../components/Gestionnaire/Device";

export default function Gestionnaire() {
  return (
    <div>
      <Helmet>
        <title>Gestionnaire de Contrôle d'Accès - Osly Solutions</title>
        <meta
          name="description"
          content="Découvrez comment Osly Solutions aide les gestionnaires à simplifier la gestion des accès grâce à une solution complète, intégrant API, application web, et module connecté."
        />
        <meta
          name="keywords"
          content="Gestionnaire, Contrôle d'accès, Osly Solutions, API, Application web, Module connecté, Sécurité des accès, Automatisation"
        />
        <meta name="author" content="Osly Solutions" />
        <link
          rel="canonical"
          href="https://www.oslysolutions.fr/gestionnaire"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <Hero />
        <Workflow />
        <Api />
        <WebApp />
        <Device />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
