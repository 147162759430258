import React from "react";
import Lottie from "lottie-react";
import animation from "../../assets/animations/open_door.json";
import img_securite from "../../assets/icones/Icone_sécurité_données.png";
import img_sans_clé from "../../assets/icones/Icone_sans_clés.png";
import img_confort from "../../assets/icones/Icone_ampoule_.png";
import img_partage from "../../assets/icones/Icone_partage_d'accès.png";

export default function Objectives() {
  return (
    <div className="section-with-border">
      <section className="container-fluid">
        <div className="title-holder">
          <h3>Objectifs de Notre Application Mobile</h3>
          <div className="subtitle">Votre Solution d'Accès Numérique</div>
        </div>
        <div className="card">
          <div className="row d-flex align-items-center mb-5">
            <div className="col-md-6">
              <div className="card-body bg-primary fixed-height-card-body">
                <p className="card-text">
                  Notre <span>application mobile</span> est spécialement conçue
                  pour offrir un{" "}
                  <span>accès numérique simplifié et sécurisé</span>à vos
                  bâtiments.
                </p>

                <p className="card-text">
                  Grâce à une <span>interface utilisateur intuitive</span>, vous
                  pouvez facilement gérer vos <span>accès en temps réel</span>,
                  sans avoir besoin de <span>clés physiques</span> ni de{" "}
                  <span>badges</span>.
                </p>

                <p className="card-text">
                  Avec notre application, vous bénéficiez d'une{" "}
                  <span>expérience utilisateur moderne</span> et
                  <span>pratique</span>, assurant un{" "}
                  <span>contrôle total et sans effort</span>
                  de vos accès.
                </p>
              </div>
            </div>
            <div className="col-md-5 d-flex justify-content-center mt-5">
              <Lottie animationData={animation} style={{ width: "50%" }} />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row align-items-center justify-content-around">
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_securite}
              alt="Icône de sécurité des données"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Sécurité des données</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_sans_clé}
              alt="Icône sans clé"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Accès sans badge</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_confort}
              alt="Icône confort d'utilisation"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Confort d'utilisation</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_partage}
              alt="Icône de partage d'accès"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Partage d'accès simplifié</p>
          </div>
        </div>
      </section>
    </div>
  );
}
