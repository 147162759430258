import React from "react";
import img from "../../assets/illustrations/Illustration_fonctionnement.png";
import "../../css/Workflow.css";

export default function Workflow() {
  return (
    <div className="section-with-border">
      <section className="container text-center">
        <div className="title-holder mb-4">
          <h2>Fonctionnement de Notre Solution de Contrôle d'Accès</h2>
          <p className="subtitle">
            Suivez ces étapes pour optimiser la gestion des accès de vos
            bâtiments
          </p>
        </div>
        <img
          src={img}
          className="img-fluid py-5"
          alt="Illustration du fonctionnement de la solution de contrôle d'accès"
        />
        <div className="d-flex flex-column flex-md-row justify-content-around text-center gap-5">
          <div className="workflow-step mb-3">
            <h4>Étape 1 : Automatisation des accès avec l'API</h4>
            <p>
              Utilisez notre API pour automatiser les accès et réduire les
              tâches manuelles.
            </p>
          </div>
          <div className="workflow-step mb-3">
            <h4>Étape 2 : Gestion centralisée avec l'Application Web</h4>
            <p>
              Centralisez la gestion des accès et suivez les activités en temps
              réel.
            </p>
          </div>
          <div className="workflow-step mb-3">
            <h4>Étape 3 : Clé Digitale avec l'Application Mobile</h4>
            <p>
              Offrez à vos utilisateurs une clé digitale pour un accès facile et
              sécurisé.
            </p>
          </div>
          <div className="workflow-step mb-3">
            <h4>Étape 4 : Ouverture de la porte grâce au Module Connecté</h4>
            <p>
              Utilisez notre module connecté pour une ouverture de porte sans
              contact.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
}
