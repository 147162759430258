import React from "react";
import "../css/Footer.css";
import logoLinkedIn from "../assets/Logo/linkedin.jpg";

export default function Footer() {
  return (
    <footer className="container-fluid footer">
      <div className="d-flex flex-column flex-md-row align-items-center justify-content-between mx-3 mx-md-5">
        <div className="d-flex flex-column flex-md-row align-items-center gap-3 gap-md-5">
          <a
            href="/privacy-policy"
            className="text-white"
            title="Consultez notre politique de confidentialité"
          >
            Politiques de confidentialité
          </a>
          <a
            href="/legal-notice"
            className="text-white"
            title="Voir les mentions légales"
          >
            Mentions légales
          </a>
          <a
            href="/terms-of-service"
            className="text-white"
            title="Lire nos conditions générales d'utilisation"
          >
            CGU
          </a>
        </div>
        <div className="d-flex align-items-center mt-3 mt-md-0">
          <a
            href="https://www.linkedin.com/company/oslysolutions"
            className="social-link"
            title="Suivez Osly Solutions sur LinkedIn"
          >
            <img
              src={logoLinkedIn}
              alt="Logo LinkedIn d'Osly Solutions"
              className="social-logo"
            />
          </a>
        </div>
      </div>
      <div className="text-center mt-3">
        <p className="mb-0">
          &copy; {new Date().getFullYear()} Osly Solutions. Tous droits
          réservés.
        </p>
      </div>
    </footer>
  );
}
