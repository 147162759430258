import React from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Hero from "../components/Resident/Hero";
import Stores from "../components/Resident/Stores";
import Objectives from "../components/Resident/Objectives";
import Steps from "../components/Resident/Steps";

export default function Resident() {
  return (
    <div>
      <Helmet>
        <title>
          Contrôle d'Accès pour Résidents - Accédez à Votre Bâtiment en Toute
          Simplicité
        </title>
        <meta
          name="description"
          content="Découvrez notre solution de contrôle d'accès simplifiée pour résidents. Utilisez votre téléphone pour accéder facilement à votre bâtiment sans badge physique. Profitez d'une sécurité accrue et d'une commodité inégalée."
        />
        <meta
          name="keywords"
          content="contrôle d'accès résident, accès bâtiment téléphone, solution sécurité résident, accès sans badge, sécurité bâtiment résident"
        />
        <meta
          property="og:title"
          content="Contrôle d'Accès pour Résidents - Accédez à Votre Bâtiment en Toute Simplicité"
        />
        <meta
          property="og:description"
          content="Découvrez notre solution de contrôle d'accès simplifiée pour résidents. Utilisez votre téléphone pour accéder facilement à votre bâtiment sans badge physique. Profitez d'une sécurité accrue et d'une commodité inégalée."
        />
        <meta property="og:image" content="URL_DE_VOTRE_IMAGE_OU_LOGO" />
        <meta
          property="og:url"
          content="https://www.oslysolutions.fr/resident"
        />
        <link rel="canonical" href="https://www.oslysolutions.fr/resident" />
      </Helmet>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <Hero />
        <Stores />
        <Objectives />
        <Steps />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
