import React, { useState, useEffect } from "react";
import img from "../../assets/illustrations/Illustration_support_contact.png";
import "../../css/Contact.css";

export default function Form() {
  const [formData, setFormData] = useState({
    name: "",
    firstname: "",
    email: "",
    message: "",
  });

  const [emailSended, setEmailSended] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [showSuccess, setShowSuccess] = useState(false);
  const [showError, setShowError] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.id]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const emailData = {
      to: "dorianguyomarch@oslysolutions.fr",
      name: formData.name,
      firstname: formData.firstname,
      email: formData.email,
      message: formData.message,
    };

    try {
      const response = await fetch("https://emails-backend.vercel.app/send", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(emailData),
      });

      if (response.ok) {
        setEmailSended(true);
        setEmailError("");
        setShowSuccess(true);
        setShowError(false);
        setFormData({
          name: "",
          firstname: "",
          email: "",
          message: "",
        });
      } else {
        const errorData = await response.json();
        setEmailError(`Échec de l'envoi de l'email: ${errorData.error}`);
        setEmailSended(false);
        setShowSuccess(false);
        setShowError(true);
      }
    } catch (error) {
      console.error("Failed to send email", error);
      setEmailError("Échec de l'envoi de l'email");
      setEmailSended(false);
      setShowSuccess(false);
      setShowError(true);
    }
  };

  useEffect(() => {
    let timer;
    if (showSuccess || showError) {
      timer = setTimeout(() => {
        setShowSuccess(false);
        setShowError(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showSuccess, showError]);

  return (
    <div className="container my-5">
      <div className="title-holder text-center mb-4">
        <h3>Contactez-nous</h3>
        <div className="subtitle">
          Nous sommes là pour répondre à vos questions
        </div>
      </div>
      <div className="row d-flex justify-content-between">
        <div className="col-md-4 mb-4 mb-md-0">
          <img
            src={img}
            className="img-fluid rounded-start"
            alt="Illustration support contact"
          />
        </div>
        <div className="col-md-7">
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="name" className="form-label">
                Nom
              </label>
              <input
                type="text"
                className="form-control"
                id="name"
                placeholder="Votre nom"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="firstname" className="form-label">
                Prénom
              </label>
              <input
                type="text"
                className="form-control"
                id="firstname"
                placeholder="Votre prénom"
                value={formData.firstname}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="email" className="form-label">
                Email
              </label>
              <input
                type="email"
                className="form-control"
                id="email"
                placeholder="Votre email"
                value={formData.email}
                onChange={handleChange}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="message" className="form-label">
                Message
              </label>
              <textarea
                className="form-control"
                id="message"
                rows="4"
                placeholder="Votre message"
                value={formData.message}
                onChange={handleChange}
                required
              ></textarea>
            </div>

            <div
              className={`alert alert-success ${
                showSuccess ? "alert-show" : "alert-hide"
              }`}
              role="alert"
            >
              Email envoyé avec succès ! Merci de nous avoir contacté.
            </div>
            <div
              className={`alert alert-danger ${
                showError ? "alert-show" : "alert-hide"
              }`}
              role="alert"
            >
              {emailError}
            </div>
            <button type="submit" className="btn-primary-custom">
              Envoyer
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}
