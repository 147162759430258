import React from "react";
import img from "../../assets/illustrations/Illustration_404.png";
import { Link } from "react-router-dom";

export default function NotFoundComponent() {
  return (
    <div className="container-fluid">
      <div className="row d-flex align-items-center min-vh-100">
        <div className="col-6 d-flex justify-content-center">
          <div className="d-flex flex-column align-items-center gap-5 p-5">
            <h1>Oups, cette page n'existe pas !</h1>
            <Link to="/" className="btn-primary-custom">
              Accueil
            </Link>
          </div>
        </div>
        <div className="col-6 d-flex justify-content-center">
          <img src={img} className="w-75" alt="error_404" />
        </div>
      </div>
    </div>
  );
}
