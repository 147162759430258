import React from "react";
import "../../css/Hero.css";
import img from "../../assets/images/residences.png";

export default function Hero() {
  return (
    <section className="hero-section position-relative text-center text-light">
      <img
        src={img}
        className="img-fluid hero-image"
        alt="Description de l'image"
      />
      <div className="overlay d-flex justify-content-center align-items-center">
        <div className="overlay-content d-flex flex-column align-items-center">
          <h1 className="w-75">
            Optimisez le Contrôle d'Accès de Vos Bâtiments avec Notre
            Technologie Avancée
          </h1>
          <h4>
            Découvrez comment notre système de contrôle d'accès sans contact
            simplifie la gestion des accès et améliore la sécurité.
          </h4>
        </div>
      </div>
    </section>
  );
}
