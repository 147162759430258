import React from "react";
import img_Armand from "../../assets/images/armand.jpeg";
import img_Dorian from "../../assets/images/dorian.jpeg";
import logo_Linkedin from "../../assets/Logo/linkedin.jpg";
import "../../css/AboutUs.css";

export default function AboutUs() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder text-center mb-4">
          <h3>À propos de nous</h3>
          <div className="subtitle">Les experts en contrôle d'accès</div>
        </div>
        <div className="row">
          <div className="col-md-6 mb-4">
            <div className="card border shadow-sm">
              <img
                src={img_Dorian}
                className="card-img-top rounded-circle mx-auto mt-4"
                alt="Dorian Guyomarc'h"
              />
              <div className="card-body d-flex flex-column align-items-center">
                <h5 className="card-title">Dorian Guyomarc'h</h5>
                <p className="card-text-about">
                  Co-fondateur & Directeur Général
                </p>
                <a
                  href="https://www.linkedin.com/in/dorian-guyomarc-h-2b6676174/"
                  className="social-link"
                  aria-label="Profil LinkedIn de Dorian Guyomarc'h"
                >
                  <img
                    src={logo_Linkedin}
                    alt="LinkedIn"
                    className="social-logo"
                  />
                </a>
              </div>
            </div>
          </div>
          <div className="col-md-6 mb-4">
            <div className="card border shadow-sm">
              <img
                src={img_Armand}
                className="card-img-top rounded-circle mx-auto mt-4"
                alt="Armand Petit"
              />
              <div className="card-body d-flex flex-column align-items-center">
                <h5 className="card-title">Armand Petit</h5>
                <p className="card-text-about">
                  Co-fondateur & Directeur Technique
                </p>
                <a
                  href="https://www.linkedin.com/in/armand-petit-2b3994183/"
                  className="social-link"
                  aria-label="Profil LinkedIn d'Armand Petit"
                >
                  <img
                    src={logo_Linkedin}
                    alt="LinkedIn"
                    className="social-logo"
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
