import React from "react";
import img from "../../assets/images/hero_gestionnaire.png";
import "../../css/Hero.css";

export default function Hero() {
  return (
    <section className="hero-section position-relative text-center text-light">
      <img
        src={img}
        className="img-fluid hero-image"
        alt="Gestion simplifiée des accès avec Osly Solutions"
      />
      <div className="overlay d-flex justify-content-center align-items-center">
        <div className="overlay-content d-flex flex-column align-items-center">
          <h1>Simplifiez la Gestion de Vos Accès</h1>
          <h4>
            Découvrez une solution innovante pour gérer les accès de vos
            bâtiments avec efficacité et sécurité.
          </h4>
        </div>
      </div>
    </section>
  );
}
