import React from "react";
import { Helmet } from "react-helmet";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/Contact.css";
import Form from "../components/Contact/Form";

export default function Contact() {
  return (
    <div>
      <Helmet>
        <title>Contactez-nous - Osly Solutions</title>
        <meta
          name="description"
          content="Contactez Osly Solutions pour toute question sur notre système de contrôle d'accès numérique. Nous sommes là pour vous aider."
        />
        <meta name="robots" content="index, follow" />

        {/* Open Graph Tags */}
        <meta property="og:title" content="Contactez-nous - Osly Solutions" />
        <meta
          property="og:description"
          content="Contactez Osly Solutions pour toute question sur notre système de contrôle d'accès numérique. Nous sommes là pour vous aider."
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:url"
          content="https://www.oslysolutions.fr/contact"
        />
        <meta
          property="og:image"
          content="https://wwww.oslysolutions.fr/image-de-contact.jpg"
        />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Contactez-nous - Osly Solutions" />
        <meta
          name="twitter:description"
          content="Contactez Osly Solutions pour toute question sur notre système de contrôle d'accès numérique. Nous sommes là pour vous aider."
        />
        <meta
          name="twitter:image"
          content="https://www.oslysolutions.fr/image-de-contact.jpg"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <Form />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
