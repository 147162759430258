import React from "react";
import Google from "../../assets/images/Button_Play_Store.png";
import Apple from "../../assets/images/Button_Apple_Stores.png";
import "../../css/Stores.css"; // Assurez-vous de créer ce fichier CSS pour les styles

export default function Stores() {
  return (
    <div
      className="d-flex align-items-center justify-content-evenly bg-primary gap-1"
      style={{ height: "150px" }}
    >
      <h3 className="text-white text-center">
        Téléchargez notre application depuis les stores d'applications
      </h3>

      <div className="d-flex flex-column flex-md-row align-items-center gap-3 me-1">
        <a
          href="https://play.google.com/store/apps/details?id=com.oslysolutions" // URL mise à jour
          target="_blank"
          rel="noopener noreferrer"
          title="Téléchargez Osly Solutions sur Google Play Store"
        >
          <img
            className="store-button"
            src={Google}
            alt="Téléchargez Osly Solutions sur Google Play Store"
          />
        </a>
        <a
          href="https://apps.apple.com/fr/app/osly-solutions" // URL mise à jour
          target="_blank"
          rel="noopener noreferrer"
          title="Téléchargez Osly Solutions sur Apple App Store"
        >
          <img
            className="store-button"
            src={Apple}
            alt="Téléchargez Osly Solutions sur Apple App Store"
          />
        </a>
      </div>
    </div>
  );
}
