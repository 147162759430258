import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "../css/Faq.css";
import Accordion from "../components/Faq/Accordion";
import { Helmet } from "react-helmet";

export default function Faq() {
  return (
    <div>
      <Helmet>
        <title>FAQ - Osly Solutions</title>
        <meta
          name="description"
          content="Découvrez les réponses aux questions fréquemment posées sur les solutions de contrôle d'accès Osly. Trouvez des informations utiles pour les gestionnaires et les résidents."
        />
        <meta name="robots" content="index, follow" />
        <meta property="og:title" content="FAQ - Osly Solutions" />
        <meta
          property="og:description"
          content="Découvrez les réponses aux questions fréquemment posées sur les solutions de contrôle d'accès Osly. Trouvez des informations utiles pour les gestionnaires et les résidents."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oslysolutions.fr/faq" />
        <meta
          property="og:image"
          content="https://www.oslysolutions.fr/image-de-preview.jpg"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <Accordion />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
