import React from "react";
import img from "../../assets/illustrations/Illustration_avantages.png";
import img_economie from "../../assets/icones/Icone_euros_.png";
import img_gestion from "../../assets/icones/Icone_gestion_simplifiée.png";
import img_partage from "../../assets/icones/Icone_partage_d'accès.png";
import img_automatisation from "../../assets/icones/Icone_API.png";
import "../../css/Advantages.css";

export default function Advantages() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder text-center mb-4">
          <h3>Nos Avantages</h3>
          <div className="subtitle">
            Les bénéfices du contrôle d'accès avec Osly Solutions
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 d-flex justify-content-center align-items-center mb-4 mb-md-0">
            <img
              src={img}
              className="img-fluid"
              alt="Avantages du contrôle d'accès"
            />
          </div>
          <div className="col-md-6 d-flex flex-md-row flex-wrap flex-column align-items-center justify-content-center">
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center text-center mb-2 fixed-size">
              <div className="advantage-card p-3 shadow-sm border h-100 w-100 d-flex flex-column justify-content-evenly align-items-center">
                <div>
                  <h3 className="advantage-title">Économie</h3>
                  <img
                    src={img_economie}
                    className="advantage-icon mb-2"
                    alt="Économie"
                  />
                </div>
                <p className="advantage-text">
                  Réduisez les coûts associés à l'impression et à la
                  distribution des badges physiques avec notre solution de{" "}
                  <span>contrôle d'accès numérique</span>.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center text-center mb-2 mx-2 fixed-size">
              <div className="advantage-card p-3 shadow-sm border h-100 w-100 d-flex flex-column justify-content-evenly align-items-center">
                <div>
                  <h3 className="advantage-title">Gestion Simplifiée</h3>
                  <img
                    src={img_gestion}
                    className="advantage-icon mb-2"
                    alt="Gestion simplifiée"
                  />
                </div>
                <p className="advantage-text">
                  Facilitez la <span>gestion des accès</span> avec notre
                  interface intuitive et centralisée, offrant un contrôle
                  d'accès simplifié et efficace.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center text-center mb-2 fixed-size">
              <div className="advantage-card p-3 shadow-sm border h-100 w-100 d-flex flex-column justify-content-evenly align-items-center">
                <div>
                  <h3 className="advantage-title">Partage d'Accès</h3>
                  <img
                    src={img_partage}
                    className="advantage-icon mb-2"
                    alt="Partage d'accès"
                  />
                </div>
                <p className="advantage-text">
                  Partagez les droits d'accès de manière sécurisée et flexible
                  grâce à notre solution de{" "}
                  <span>contrôle d'accès numérique</span>.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 d-flex flex-column justify-content-center align-items-center text-center mb-2 mx-2 fixed-size">
              <div className="advantage-card p-3 shadow-sm border h-100 w-100 d-flex flex-column justify-content-evenly align-items-center">
                <div>
                  <h3 className="advantage-title">Automatisation</h3>
                  <img
                    src={img_automatisation}
                    className="advantage-icon mb-2"
                    alt="Automatisation"
                  />
                </div>
                <p className="advantage-text">
                  Automatisez les processus de gestion des accès pour augmenter
                  votre <span>efficacité</span> et réduire les tâches manuelles.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
