import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import CGUDescription from "../components/Cgu/CGUDescription";

export default function TermsOfService() {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <CGUDescription />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
