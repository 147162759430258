import React from "react";

export default function CGUDescription() {
  return (
    <div className="container my-5">
      <div className="title-holder text-center mb-4">
        <h3>Conditions Générales d'Utilisation</h3>
        <div className="subtitle">
          Veuillez lire attentivement les conditions régissant l'utilisation de
          nos services.
        </div>
      </div>
      <div>
        <h4>1. Introduction</h4>
        <p>
          Les présentes Conditions Générales d'Utilisation (les "Conditions")
          régissent l'utilisation du site Internet et de l'application mobile
          Osly Solutions (les "Sites") mis à disposition par la société Osly
          Solutions. Des services et équipements connectés proposés par Osly
          Solutions. En utilisant ou en visitant les Sites, vous acceptez d'être
          lié par les présentes Conditions, incluant notre Politique de
          Confidentialité.
        </p>
        <p>
          L’édition des Sites Osly Solutions est assurée par Osly Solutions SAS,
          au capital de 100 euros, immatriculée sous le numéro 94779067100012,
          dont le siège social est situé au 27 route du Muguet, 44450
          Saint-Julien-De-Concelles.
        </p>
        <p>Adresse e-mail : dorianguyomarch.osly@gmail.com</p>
        <p>Le Directeur de la publication est : Monsieur Dorian Guyomarc’h</p>
        <p>Numéro de TVA intracommunautaire : FR86977524735</p>
        <p>
          L’hébergeur des Sites Osly Solutions est la société Supabase, dont le
          siège social est situé au 65 CHULIA STREET, #38-02/03, OCBC CENTRE,
          Singapore 049513.
        </p>
        <h4>2. Définitions</h4>
        <p>
          <strong>Propriétaire / Copropriétaire :</strong> Toute personne
          physique ou morale propriétaire d’un logement ou d’un bien immobilier
          et ayant souscrit aux Services.
        </p>
        <p>
          <strong>Équipement :</strong> Désigne les dispositifs connectés pour
          lesquels les services d'Osly Solutions sont fournis.
        </p>
        <p>
          <strong>Identifiants :</strong> Codes d’accès et mots de passe
          personnels permettant au Propriétaire / Copropriétaire de s’identifier
          et d’utiliser les Services.
        </p>
        <p>
          <strong>Logiciels :</strong> Programmes et applications embarqués dans
          les Équipements et nécessaires à la fourniture des Services.
        </p>
        <p>
          <strong>Services :</strong> Ensemble des services fournis par Osly
          Solutions permettant de configurer, contrôler, piloter et utiliser les
          Équipements.
        </p>
        <p>
          <strong>Utilisateur :</strong> Désigne de façon générique les
          Propriétaires / Copropriétaires et les Invités utilisant les Services.
        </p>
        <h4>3. Mise à jour des Conditions</h4>
        <p>
          Osly Solutions peut à tout moment et à sa discrétion modifier les
          présentes Conditions en publiant une version modifiée sur les Sites.
          Toute version modifiée entre immédiatement en vigueur dès sa
          publication.
        </p>
        <h4>4. Mises à jour des Sites</h4>
        <p>
          Osly Solutions se réserve le droit, à tout moment, de modifier,
          altérer ou mettre à jour les Sites, sans notification préalable. Les
          Utilisateurs consentent à ces mises à jour.
        </p>
        <h4>5. Utilisation et accès aux Sites</h4>
        <p>
          Les Utilisateurs s’engagent irrévocablement à ne pas pirater, abuser
          ou perturber le fonctionnement des Sites. Les Utilisateurs
          s’interdisent d’utiliser les Sites et leur contenu dans le but de
          porter atteinte aux droits de tiers ou dans le but d’obtenir des
          informations personnelles sur des tiers.
        </p>
        <p>
          Les Sites sont accessibles gratuitement en tout lieu à tout
          Utilisateur ayant un accès à Internet. Tous les frais supportés par
          les Utilisateurs pour accéder au service (matériel informatique,
          logiciels, connexion Internet, etc.) sont à leur charge.
        </p>
        <p>
          Les Utilisateurs non-membres n’ont pas accès aux services réservés
          disponibles sur l’application mobiles. En acceptant de s’inscrire aux
          services réservés, le Propriétaire / Copropriétaire et l’Invité membre
          s’engagent à fournir des informations sincères et exactes concernant
          leurs coordonnées et leurs données personnelles.
        </p>
        <h4>6. Accès aux Services</h4>
        <p>
          L’utilisation des Services est subordonnée à la compatibilité des
          Équipements avec les appareils utilisés par les Utilisateurs. Les
          Utilisateurs sont responsables de l’installation conforme des
          Équipements et de l’application mobile, ainsi que de la préservation
          de leurs Identifiants et mots de passe.
        </p>
        <p>
          Lors de la souscription aux Services, les Utilisateurs sont invités à
          communiquer à Osly Solutions une adresse email valide. Lors de la
          souscription aux Services, Osly Solutions transmet au Propriétaire /
          Copropriétaire des codes d’accès personnels et confidentiels, que le
          Propriétaire / Copropriétaire ne peut en aucun cas transmettre à des
          tiers. L’utilisation et la préservation des identifiants et mots de
          passe relèvent de la seule responsabilité des Utilisateurs. Ainsi,
          toute perte ou divulgation involontaire d’élément susceptible de
          permettre à un tiers de prendre connaissance des identifiants et mots
          de passe d’un Propriétaire / Copropriétaire ou d’un Utilisateur doit
          être immédiatement signalée par écrit à Osly Solutions.
        </p>
        <p>
          En cas de cession ou de transfert de l’Équipement, les Services seront
          résiliés automatiquement, sans préavis, à la date de transfert/cession
          de l’Équipement. Il appartient au nouveau Propriétaire /
          Copropriétaire et à l’ancien Propriétaire / Copropriétaire de
          l’Équipement de communiquer à Osly Solutions les éléments justifiant
          de la cession ou du transfert de l’Équipement ainsi que les
          coordonnées de l’ancien Copropriétaire. Le plus diligent du nouveau ou
          de l’ancien Propriétaire / Copropriétaire libérant le second de son
          obligation d’information à l’égard de Osly Solutions.
        </p>
        <h4>7. Utilisation des Services</h4>
        <p>
          Les Services doivent être utilisés conformément à leur destination
          normale et à des fins licites. Osly Solutions pourra, en cas de
          violation des présentes Conditions, désactiver les Identifiants et
          l’accès aux Services.
        </p>
        <h4>8. Résiliation pour manquement</h4>
        <p>
          En cas de violation des Conditions, Osly Solutions se réserve le droit
          de résilier l’accès aux Services et aux Sites sans préavis. Tout
          Utilisateur inscrit sur l’application mobile pourra également
          solliciter sa désinscription en contactant son gestionnaire
          d’immeuble.
        </p>
        <h4>9. Disponibilité et sécurité</h4>
        <p>
          Osly Solutions met en œuvre ses meilleurs efforts pour fournir un
          accès 24h/24 et 7j/7 aux Services mais ne peut garantir un accès
          ininterrompu, notamment en cas de survenance d’un événement
          imprévisible échappant à son contrôle.
        </p>
        <h4>10. Responsabilités</h4>
        <p>
          <strong>Responsabilités des Utilisateurs :</strong> Les Utilisateurs
          sont responsables de l’utilisation conforme des Services et de leurs
          Identifiants.
        </p>
        <p>
          <strong>Responsabilité d'Osly Solutions :</strong> Osly Solutions
          n’est tenue qu’à une obligation de moyens et sa responsabilité est
          limitée aux dommages directs, prévisibles et directement causés par sa
          faute.
        </p>
        <h4>11. Maintenance ou évolutions techniques</h4>
        <p>
          Osly Solutions se réserve le droit de procéder, à tout moment, à des
          interruptions de service pour des opérations de maintenance ou de mise
          à jour.
        </p>
        <h4>12. Propriété Intellectuelle</h4>
        <p>
          Les marques, logos, signes et contenus des Sites (textes, images, son,
          etc.) sont protégés par le Code de la propriété intellectuelle. Toute
          reproduction, publication ou exploitation des contenus sans
          autorisation est interdite.
        </p>
        <h4>13. Confidentialité et données personnelles</h4>
        <p>
          Osly Solutions assure une collecte et un traitement des informations
          personnelles dans le respect de la vie privée conformément à la loi
          n°78-17 du 6 janvier 1978 relative à l’informatique, aux fichiers et
          aux libertés. Les Utilisateurs disposent d’un droit d’accès, de
          rectification, de suppression et d’opposition de leurs données
          personnelles qu'ils peuvent exercer par mail à l'adresse
          armandpetit.osly@gmail.com
        </p>
        <h4>14. Liens hypertextes</h4>
        <p>
          Les Sites peuvent contenir des liens hypertextes vers d'autres sites.
          Osly Solutions n’a aucun contrôle sur ces sites et ne saurait être
          responsable de leur contenu.
        </p>
        <h4>15. Cookies</h4>
        <p>
          Les Sites utilisent des cookies pour améliorer l’expérience
          utilisateur. En naviguant sur les Sites, les Utilisateurs acceptent
          l’utilisation de cookies.
        </p>
        <h4>16. Contact / Assistance</h4>
        <p>
          Les Utilisateurs peuvent adresser une réclamation ou une demande
          d’informations à l’adresse email suivante : contact@oslysolutions.com.
        </p>
        <h4>17. Cession de Contrat</h4>
        <p>
          Les droits et obligations des Utilisateurs dans le cadre de leur
          relation avec Osly Solutions sont incessibles sans consentement
          préalable d'Osly Solutions. Osly Solutions peut librement céder ses
          droits et obligations.
        </p>
        <h4>18. Nullité</h4>
        <p>
          Si une stipulation des présentes Conditions est tenue pour non valide,
          les autres stipulations demeureront en vigueur.
        </p>
        <h4>19. Loi applicable – Règlement des différends</h4>
        <p>
          Les relations entre les parties sont régies par la loi française. Les
          tribunaux du lieu du domicile du Propriétaire / Copropriétaire seront
          compétents pour régler les différends.
        </p>
        <p>
          En utilisant les Services et les Sites d'Osly Solutions, vous acceptez
          les présentes Conditions Générales d'Utilisation.
        </p>
      </div>
    </div>
  );
}
