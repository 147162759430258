import React from "react";
import img from "../../assets/illustrations/Illustration_boitier.png";
import img_compatible from "../../assets/icones/Icone_compatible.png";
import img_sans_cout from "../../assets/icones/Icone_sans_couts.png";
import img_clé_molette from "../../assets/icones/Icone_clés_molette.png";

export default function Device() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder mb-4">
          <h2>Module Connecté pour la Gestion des Accès</h2>
          <p className="subtitle">
            Ouvrez vos portes de manière pratique et sécurisée.
          </p>
        </div>
        <div className="card">
          <div className="row d-flex align-items-center">
            <div className="col-md-6">
              <div className="card-body bg-secondary rounded">
                <p className="card-text">
                  Le <span>module connecté</span> utilise la technologie{" "}
                  <span>Bluetooth Low Energy</span> pour communiquer directement
                  avec l'application mobile.
                </p>
                <p className="card-text">
                  Cette technologie permet une{" "}
                  <span>ouverture de porte sans contact</span>, offrant une{" "}
                  <span>expérience utilisateur fluide et moderne</span>.
                </p>
                <p className="card-text">
                  En intégrant ce module à votre système, vous assurez une
                  gestion <span>pratique et sécurisée des accès</span>, tout en
                  éliminant le besoin de{" "}
                  <span>clés physiques ou de badges</span>.
                </p>
              </div>
            </div>
            <div className="col-md-6 d-flex justify-content-center mt-3">
              <img
                src={img}
                alt="Module connecté pour la gestion des accès"
                className="img-fluid"
                style={{ maxWidth: "50%" }}
              />
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-around mt-5">
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_compatible}
              alt="Compatibilité avec divers systèmes"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Compatibilité</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_sans_cout}
              alt="Sans coût supplémentaire"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Sans coût supplémentaire</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_clé_molette}
              alt="Installation rapide"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Installation rapide</p>
          </div>
        </div>
      </section>
    </div>
  );
}
