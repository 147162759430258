import React from "react";
import img from "../../assets/illustrations/illustration_solution.png";
import "../../css/Solution.css";

export default function Solution() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder">
          <h3>Solution</h3>
          <div className="subtitle">
            Modernisez le contrôle d'accès avec Osly Solutions
          </div>
        </div>
        <div className="card">
          <div className="row g-0">
            <div className="col-md-4 d-flex align-items-center">
              <img
                src={img}
                className="img-fluid rounded-start"
                alt="Solution de contrôle d'accès"
              />
            </div>
            <div className="col-md-8 d-flex align-items-center">
              <div className="card-body bg-secondary fixed-height-card-body">
                <p className="card-text">
                  Osly Solutions propose une{" "}
                  <span>solution de contrôle d'accès innovante</span> pour les
                  bâtiments. Notre système digitalisé remplace les badges
                  traditionnels par une solution moderne basée sur la
                  technologie Bluetooth. Avec notre offre, vous bénéficiez de :
                </p>
                <ul className="card-text">
                  <li>
                    <span>API</span> : Automatisation du contrôle d'accès
                  </li>
                  <li>
                    <span>Application web</span> : Gestion centralisée des accès
                  </li>
                  <li>
                    <span>Application mobile</span> : Accès digitalisé via votre
                    téléphone
                  </li>
                  <li>
                    <span>Module connecté</span> : Ouverture sans contact des
                    portes
                  </li>
                </ul>
                <p className="card-text">
                  Nous offrons une <span>alternative moderne et sécurisée</span>{" "}
                  aux systèmes de contrôle d'accès traditionnels.
                </p>
                <p className="card-text">
                  Les gestionnaires peuvent{" "}
                  <span>surveiller et contrôler en temps réel</span> les accès
                  des résidents et des maintenanciers,{" "}
                  <span>simplifiant ainsi la gestion</span> et renforçant la
                  sécurité.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
