import React from "react";

export default function LegalNoticeDescription() {
  return (
    <div className="container my-5">
      <div className="title-holder text-center mb-4">
        <h3>Mentions légales</h3>
        <div className="subtitle">
          Veuillez lire attentivement nos mentions légales.
        </div>
      </div>
      <div>
        Conformément aux dispositions des Articles 6-III et 19 de la Loi
        n°2004-575 du 21 juin 2004 pour la Confiance dans l’économie numérique,
        dite L.C.E.N. Il est porté à la connaissance des utilisateurs et
        visiteurs, du site https://www.oslysolutions.fr , les présentes mentions
        légales. La connexion et la navigation sur le Site par l’Utilisateur
        implique l'acceptation intégrale et sans réserve des présentes mentions
        légales. Ces dernières sont accessibles sur le Site à la rubrique «
        Mentions légales ».
        <br />
        <br />
        <h4>1. Informations éditeurs :</h4>
        Nom de la société : Osly Solutions
        <br />
        Adresse postale : 27 route du Muguet, 44450 Saint-Julien-De-Concelles
        <br />
        Adresse e-mail : dorianguyomarch.osly@gmail.com
        <br />
        Nom du directeur de la publication : Dorian Guyomarc’h
        <br />
        Numéro de SIREN : 97752473500015
        <br />
        Numéro TVA : FR86977524735
        <br />
        <br />
        <h4>2. Hébergement :</h4>
        Nom de l’hébergement : Bubble Group, Inc.
        <br />
        Adresse : 22 W 21st Street, 2nd Floor, New York, NY 10010
        <br />
        Numéro de téléphone :
        <br />
        <br />
        <h4>3. Accès au site :</h4>
        Le Site est accessible en tout endroit, 7j/7, 24h/24 sauf cas de force
        majeure, interruption programmée ou non et pouvant découler d’une
        nécessité de maintenance. En cas de modification, interruption ou
        suspension du Site, l'Editeur ne saurait être tenu responsable.
        <br />
        <br />
        <h4>4. Déclaration à la CNIL :</h4>
        Conformément à la loi 78-17 du 6 janvier 1978 (modifiée par la loi
        2004-801 du 6 août 2004 relative à la protection des personnes physiques
        à l’égard des traitements de données à caractère personnel) relative à
        l’informatique, aux fichiers et aux libertés, ce site n’a pas fait
        l’objet d’une déclaration auprès de la Commission nationale de
        l’informatique et des libertés (www.cnil.fr).
        <br />
        <br />
        <h4>5. Collecte des données :</h4>
        Le Site assure à l'Utilisateur une collecte et un traitement
        d'informations personnelles dans le respect de la vie privée
        conformément à la loi n°78-17 du 6 janvier 1978 relative à
        l'informatique, aux fichiers et aux libertés. En vertu de la loi
        Informatique et Libertés, en date du 6 janvier 1978, l'Utilisateur
        dispose d'un droit d'accès, de rectification, de suppression et
        d'opposition de ses données personnelles. L'Utilisateur exerce ce droit
        via l’adresse mail suivante : armandpetit.osly@gmail.com.
        <br />
        <br />
        Toute utilisation, reproduction, diffusion, commercialisation,
        modification de toute ou partie du Site, sans autorisation de l'Éditeur
        est prohibée et pourra entraîner des actions et poursuites judiciaires
        telles que notamment prévues par le Code de la propriété intellectuelle
        et le Code civil.
        <br />
        <br />
        Pour plus d’informations, se reporter aux CGU du site
        https://www.oslysolutions.fr accessible à la rubrique "CGU". Pour plus
        d'informations en matière de protection des données à caractère
        personnel, se reporter à la Charte en matière de protection des données
        à caractère personnel du site https://www.oslysolutions.fr accessible à
        la rubrique "Données personnelles".
      </div>
    </div>
  );
}
