import React from "react";
import img_gestionnaire from "../../assets/images/gestionnaire.png";
import img_resident from "../../assets/images/resident.png";
import "../../css/Users.css";
import { Link } from "react-router-dom"; // Importer Link pour la navigation

export default function Users() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder">
          <h3>Les bénéficiaires</h3>
          <div className="subtitle">
            Avantages du contrôle d'accès pour tous les utilisateurs
          </div>
        </div>
        <div className="row g-2">
          <div className="col-md-6">
            <div className="card text-white user-card">
              <img
                src={img_gestionnaire}
                className="card-img"
                alt="Gestionnaire utilisant le contrôle d'accès"
              />
              <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center gap-1">
                <h5 className="card-title">Gestionnaire</h5>
                <div className="card-text-overlay">
                  <p className="card-text text-white">
                    Avec Osly Solutions, les gestionnaires bénéficient d'une{" "}
                    <span>solution avancée de contrôle d'accès</span> qui
                    simplifie la gestion des accès aux bâtiments. Ils peuvent{" "}
                    <span>réduire les coûts liés aux badges physiques</span> et{" "}
                    <span>améliorer la sécurité</span> grâce à une{" "}
                    <span>gestion en temps réel</span> des accès.
                  </p>
                  <Link to="/gestionnaire" className="btn-primary-custom">
                    Découvrir
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="card user-card">
              <img
                src={img_resident}
                className="card-img"
                alt="Résident utilisant le contrôle d'accès"
              />
              <div className="card-img-overlay d-flex flex-column justify-content-center align-items-center gap-1">
                <h5 className="card-title">Résident</h5>
                <div className="card-text-overlay">
                  <p className="card-text text-white">
                    Les résidents profitent d'un{" "}
                    <span>accès simplifié et sécurisé</span> grâce à
                    l'application mobile d'Osly Solutions. Fini les{" "}
                    <span>problèmes de badges perdus ou volés</span>, ils
                    peuvent désormais{" "}
                    <span>
                      gérer leurs accès directement depuis leur smartphone
                    </span>
                    .
                  </p>
                  <Link to="/resident" className="btn-primary-custom">
                    Découvrir
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
