import React from "react";
import NotFoundComponent from "../components/NotFound/NotFoundComponent";

export default function NotFound() {
  return (
    <div>
      <NotFoundComponent />
    </div>
  );
}
