import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import LegalNoticeDescription from "../components/LegalNotice/LegalNoticeDescription";

export default function LegalNotice() {
  return (
    <div>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <LegalNoticeDescription />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
