import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./css/custom.css";
import "./App.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";

import Home from "./pages/Home";
import Gestionnaire from "./pages/Gestionnaire";
import Resident from "./pages/Resident";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import AnimatedRoutes from "./components/AnimatedRoutes"; // Assurez-vous du bon chemin d'import
import ScrollToTop from "./components/ScrollTop";
import TermsOfService from "./pages/TermsOfService";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import LegalNotice from "./pages/LegalNotice";

function App() {
  return (
    <BrowserRouter>
      <AnimatedRoutes>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/gestionnaire" element={<Gestionnaire />} />
          <Route path="/resident" element={<Resident />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/legal-notice" element={<LegalNotice />} />
          <Route path="/terms-of-service" element={<TermsOfService />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </AnimatedRoutes>
    </BrowserRouter>
  );
}

export default App;
