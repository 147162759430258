import React from "react";

export default function Accordion() {
  return (
    <div className="container my-5">
      <div className="title-holder text-center mb-4">
        <h1>Questions Fréquemment Posées</h1>
        <p className="subtitle">
          Trouvez des réponses à vos questions sur nos solutions de contrôle
          d'accès
        </p>
      </div>

      {/* Section pour les gestionnaires */}
      <section className="my-5">
        <h2 className="text-center">FAQ pour les Gestionnaires</h2>
        <div className="accordion" id="accordionGestionnaire">
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGOne"
                aria-expanded="false"
                aria-controls="collapseGOne"
              >
                Comment puis-je m'inscrire en tant que gestionnaire ?
              </button>
            </h3>
            <div
              id="collapseGOne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Pour vous inscrire en tant que gestionnaire de bâtiment,
                veuillez nous contacter directement. Nous vous fournirons toutes
                les informations nécessaires pour compléter votre inscription
                dans le cadre de nos solutions de gestion d'accès.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGTwo"
                aria-expanded="false"
                aria-controls="collapseGTwo"
              >
                Est-ce que mon bâtiment doit avoir des portes électrifiées pour
                utiliser Osly Solutions ?
              </button>
            </h3>
            <div
              id="collapseGTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Oui, notre système de contrôle d'accès est compatible avec les
                bâtiments équipés de portes électrifiées, qu'ils soient anciens
                ou modernes, garantissant une intégration facile et rapide.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGThree"
                aria-expanded="false"
                aria-controls="collapseGThree"
              >
                Osly Solutions est-il sécurisé contre les copies d'accès ?
              </button>
            </h3>
            <div
              id="collapseGThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Oui, notre solution de gestion d'accès utilise des technologies
                avancées pour empêcher les copies d'accès. La sécurité des
                données est assurée par nos applications web et mobiles,
                garantissant une protection complète.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGFour"
                aria-expanded="false"
                aria-controls="collapseGFour"
              >
                Est-ce que l'installation du module Osly nécessite des travaux
                importants ?
              </button>
            </h3>
            <div
              id="collapseGFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Non, l'installation du module Osly est simple et ne nécessite
                pas de câblage supplémentaire. Elle peut être effectuée lors de
                la maintenance habituelle par un électricien, réduisant ainsi
                les coûts et les perturbations.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGFive"
                aria-expanded="false"
                aria-controls="collapseGFive"
              >
                Comment gérer les accès des nouveaux résidents ou utilisateurs ?
              </button>
            </h3>
            <div
              id="collapseGFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Les gestionnaires peuvent facilement ajouter, modifier ou
                supprimer des accès via l'application web Osly. Les nouveaux
                résidents reçoivent des instructions par e-mail pour créer leur
                compte sur l'application mobile.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGSix"
                aria-expanded="false"
                aria-controls="collapseGSix"
              >
                Comment accorder un accès temporaire à un prestataire ?
              </button>
            </h3>
            <div
              id="collapseGSix"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Avec l'application web, vous pouvez accorder des accès
                temporaires en quelques clics. Définissez des plages horaires
                spécifiques et révoquez l'accès à tout moment selon vos besoins.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGSeven"
                aria-expanded="false"
                aria-controls="collapseGSeven"
              >
                Comment le module Osly s'intègre-t-il avec le système de
                sécurité existant de mon bâtiment ?
              </button>
            </h3>
            <div
              id="collapseGSeven"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                Le module Osly est conçu pour s'intégrer avec les systèmes de
                sécurité existants sans modifications majeures. Cette
                compatibilité permet une mise en œuvre fluide avec les
                infrastructures actuelles.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseGEight"
                aria-expanded="false"
                aria-controls="collapseGEight"
              >
                Quels sont les avantages de l'utilisation de Bluetooth BLE pour
                Osly Solutions ?
              </button>
            </h3>
            <div
              id="collapseGEight"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionGestionnaire"
            >
              <div className="accordion-body">
                La technologie Bluetooth BLE assure une communication sécurisée
                et efficace sur de courtes distances. Elle est moins sujette aux
                interférences par rapport aux fréquences radio et GSM,
                améliorant ainsi la fiabilité des accès dans les environnements
                urbains.
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Section pour les résidents */}
      <section className="my-5">
        <h2 className="text-center">FAQ pour les Résidents</h2>
        <div className="accordion" id="accordionResident">
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseROne"
                aria-expanded="false"
                aria-controls="collapseROne"
              >
                Pourquoi devrais-je utiliser l'application mobile Osly Solutions
                plutôt qu'un badge physique ?
              </button>
            </h3>
            <div
              id="collapseROne"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionResident"
            >
              <div className="accordion-body">
                L'application Osly Solutions offre une sécurité accrue et une
                commodité sans pareil. Vous pouvez accéder aux portes de votre
                bâtiment directement depuis votre smartphone, évitant ainsi les
                risques liés à la perte ou au vol d'un badge physique.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRTwo"
                aria-expanded="false"
                aria-controls="collapseRTwo"
              >
                Comment puis-je être sûr que l'application est sécurisée ?
              </button>
            </h3>
            <div
              id="collapseRTwo"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionResident"
            >
              <div className="accordion-body">
                L'application Osly Solutions utilise des technologies de
                cryptage avancées pour assurer la protection de vos données
                personnelles. Conformément au RGPD, nous garantissons que vos
                informations sont sécurisées et confidentielles.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRThree"
                aria-expanded="false"
                aria-controls="collapseRThree"
              >
                Que faire si je ne reçois pas l'e-mail pour créer mon compte ?
              </button>
            </h3>
            <div
              id="collapseRThree"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionResident"
            >
              <div className="accordion-body">
                Si vous ne recevez pas l'e-mail de création de compte, contactez
                le gestionnaire de votre bâtiment ou utilisez l'onglet "Contact"
                dans l'application pour obtenir de l'aide. Nous nous engageons à
                résoudre ce problème rapidement pour vous permettre d'utiliser
                l'application sans délai.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRFour"
                aria-expanded="false"
                aria-controls="collapseRFour"
              >
                Que faire si je rencontre des problèmes pour accéder aux portes
                ?
              </button>
            </h3>
            <div
              id="collapseRFour"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionResident"
            >
              <div className="accordion-body">
                Si vous avez des problèmes d'accès aux portes, assurez-vous que
                le Bluetooth et la localisation de votre téléphone sont activés.
                En cas de persistance du problème, contactez notre support ou le
                gestionnaire du bâtiment pour obtenir une assistance rapide.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h3 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#collapseRFive"
                aria-expanded="false"
                aria-controls="collapseRFive"
              >
                Puis-je donner temporairement accès à mon immeuble à des
                visiteurs ou amis ?
              </button>
            </h3>
            <div
              id="collapseRFive"
              className="accordion-collapse collapse"
              data-bs-parent="#accordionResident"
            >
              <div className="accordion-body">
                Oui, l'application Osly Solutions vous permet de partager
                l'accès à votre bâtiment avec des visiteurs ou amis de manière
                temporaire ou permanente. Vous pouvez définir les permissions et
                la durée de l'accès via l'application.
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
