import React from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/Header.css";
import img from "../assets/Logo/horizontal_title.png";

function Header() {
  const location = useLocation(); // Utiliser le hook useLocation pour obtenir l'URL actuelle

  return (
    <nav
      className="navbar navbar-expand-lg fixed-top bg-navbar-gray border"
      aria-label="Navigation principale"
    >
      <div className="container">
        <Link
          className="navbar-brand"
          to="/"
          aria-label="Page d'accueil d'Osly Solutions"
        >
          <img
            src={img}
            alt="Logo d'Osly Solutions"
            className="navbar-logo"
            style={{ height: "75px", width: "auto" }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" aria-hidden="true"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav mx-auto mb-2 mb-lg-0 d-flex justify-content-center align-items-center">
            <li
              className={`nav-item mx-2 ${
                location.pathname === "/" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/" ? "active" : ""
                }`}
                to="/"
                aria-label="Accédez à la page d'accueil"
              >
                Accueil
              </Link>
            </li>
            <li
              className={`nav-item dropdown mx-2 ${
                location.pathname === "/gestionnaire" ||
                location.pathname === "/resident"
                  ? "active"
                  : ""
              }`}
            >
              <Link
                className={`nav-link dropdown-toggle ${
                  location.pathname === "/gestionnaire" ||
                  location.pathname === "/resident"
                    ? "active"
                    : ""
                }`}
                to="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-label="Menu des produits"
              >
                Produit
              </Link>
              <ul
                className="dropdown-menu"
                aria-label="Menu déroulant des produits"
              >
                <li>
                  <Link
                    className={`dropdown-item ${
                      location.pathname === "/gestionnaire" ? "active" : ""
                    }`}
                    to="/gestionnaire"
                    aria-label="Page du gestionnaire"
                  >
                    Gestionnaire
                  </Link>
                </li>
                <li>
                  <Link
                    className={`dropdown-item ${
                      location.pathname === "/resident" ? "active" : ""
                    }`}
                    to="/resident"
                    aria-label="Page résident"
                  >
                    Résident
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={`nav-item mx-2 ${
                location.pathname === "/faq" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/faq" ? "active" : ""
                }`}
                to="/faq"
                aria-label="Page FAQ"
              >
                FAQ
              </Link>
            </li>
            <li
              className={`nav-item mx-2 ${
                location.pathname === "/contact" ? "active" : ""
              }`}
            >
              <Link
                className={`nav-link ${
                  location.pathname === "/contact" ? "active" : ""
                }`}
                to="/contact"
                aria-label="Page Contact"
              >
                Contact
              </Link>
            </li>
          </ul>
          <Link
            to="https://www.calendly.com/dorianguyomarch-osly"
            aria-label="Planifiez une démo avec Osly Solutions"
          >
            <button className="btn-secondary-custom">Obtenir une démo</button>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Header;
