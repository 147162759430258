import React from "react";
import img from "../../assets/images/hero_resident.png";
import "../../css/Hero.css";

export default function Hero() {
  return (
    <section className="hero-section position-relative text-center text-light">
      <img
        src={img}
        className="img hero-image"
        alt="Accès au bâtiment avec smartphone grâce à Osly Solutions"
      />
      <div className="overlay d-flex justify-content-center align-items-center">
        <div className="overlay-content d-flex flex-column align-items-center">
          <h1 className="w-75">
            Accédez Facilement à Votre Bâtiment avec Notre Application Mobile
          </h1>
          <h4 className="w-75">
            Avec Osly Solutions, accédez à votre bâtiment en utilisant
            simplement votre smartphone. Oubliez les clés et cartes d'accès avec
            notre solution de contrôle d'accès mobile.
          </h4>
        </div>
      </div>
    </section>
  );
}
