import React from "react";
import { Helmet } from "react-helmet";
import Users from "../components/Home/Users";
import Advantages from "../components/Home/Advantages";
import AboutUs from "../components/Home/AboutUs";
import Footer from "../components/Footer";
import Hero from "../components/Home/Hero";
import Problem from "../components/Home/Problem";
import Solution from "../components/Home/Solution";
import Header from "../components/Header";

export default function Home() {
  return (
    <div>
      <Helmet>
        <title>
          Osly Solutions – Expert en Contrôle d'Accès Numérique pour Bâtiments
        </title>
        <meta
          name="description"
          content="Découvrez Osly Solutions, votre expert en contrôle d'accès numérique. Remplacez les badges vigik avec notre système sans contact sécurisé."
        />
        <meta name="robots" content="index, follow" />
        {/* Open Graph Tags */}
        <meta
          property="og:title"
          content="Osly Solutions – Expert en Contrôle d'Accès Numérique pour Bâtiments"
        />
        <meta
          property="og:description"
          content="Découvrez Osly Solutions, votre expert en contrôle d'accès numérique. Remplacez les badges vigik avec notre système sans contact sécurisé."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.oslysolutions.fr" />
        <meta
          property="og:image"
          content="https://www.oslysolutions.fr/image-de-preview.jpg"
        />

        {/* Twitter Card Tags */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content="Osly Solutions – Expert en Contrôle d'Accès Numérique pour Bâtiments"
        />
        <meta
          name="twitter:description"
          content="Découvrez Osly Solutions, votre expert en contrôle d'accès numérique. Remplacez les badges vigik avec notre système sans contact sécurisé."
        />
        <meta
          name="twitter:image"
          content="https://www.oslysolutions.fr/image-de-preview.jpg"
        />
      </Helmet>
      <header>
        <Header />
      </header>
      <main style={{ paddingTop: "101px" }}>
        <Hero />
        <Problem />
        <Solution />
        <Users />
        <Advantages />
        <AboutUs />
      </main>
      <footer className="bg-primary">
        <Footer />
      </footer>
    </div>
  );
}
