import React from "react";
import mockup_mail from "../../assets/mockup/Mockup_mail.png";
import mockup_download from "../../assets/mockup/Mockup_appli.png";
import mockup_connexion from "../../assets/mockup/Mockup_connexion.png";
import mockup_door from "../../assets/mockup/Mockup_batiments.png";
import "../../css/Steps.css";

export default function Steps() {
  return (
    <div>
      <section>
        <div className="title-holder my-5">
          <h3>Guide d'Inscription en 4 Étapes pour l'Application Mobile</h3>
          <div className="subtitle">
            Suivez ces étapes simples pour commencer à utiliser notre
            application mobile et accéder facilement à votre bâtiment.
          </div>
        </div>
        <div
          id="carouselExampleIndicators"
          className="carousel slide bg-tertiary"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Étape 1: Invitation par Email"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="1"
              aria-label="Étape 2: Téléchargez l'Application"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="2"
              aria-label="Étape 3: Connexion à l'Application"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleIndicators"
              data-bs-slide-to="3"
              aria-label="Étape 4: Accédez à Votre Bâtiment"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="d-flex justify-content-center w-100 h-100">
                <img
                  src={mockup_mail}
                  className="d-block resize"
                  alt="Invitation par e-mail pour créer un compte"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Étape 1: Invitation par Email</h5>
                  <p>
                    Votre gestionnaire a ajouté vos informations dans sa base de
                    données, déclenchant l'envoi d'un e-mail d'invitation de la
                    part d'Osly Solutions. Suivez les instructions dans l'e-mail
                    pour confirmer la création de votre compte et définir votre
                    mot de passe.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex justify-content-center w-100 h-100">
                <img
                  src={mockup_download}
                  className="d-block resize"
                  alt="Téléchargement de l'application mobile depuis les stores"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Étape 2: Téléchargez l'Application</h5>
                  <p>
                    Une fois votre mot de passe défini, téléchargez
                    l'application mobile depuis les stores pour commencer à
                    l'utiliser.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex justify-content-center w-100 h-100">
                <img
                  src={mockup_connexion}
                  className="d-block resize"
                  alt="Connexion à l'application mobile"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Étape 3: Connectez-vous</h5>
                  <p>
                    Une fois l'application téléchargée, connectez-vous en
                    utilisant l'adresse e-mail et le mot de passe que vous avez
                    définis.
                  </p>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="d-flex justify-content-center w-100 h-100">
                <img
                  src={mockup_door}
                  className="d-block resize"
                  alt="Accès aux bâtiments via l'application mobile"
                />
                <div className="carousel-caption d-none d-md-block">
                  <h5>Étape 4: Accédez à Votre Bâtiment</h5>
                  <p>
                    Vous aurez accès aux bâtiments et aux portes autorisées par
                    votre gestionnaire. Approchez-vous de la porte et cliquez
                    sur le bouton "Ouvrir" dans l'application pour entrer.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Étape précédente</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Étape suivante</span>
          </button>
        </div>
      </section>
    </div>
  );
}
