import React from "react";
import img from "../../assets/mockup/Mockup_appli_web.png";
import img_securite from "../../assets/icones/Icone_sécurité_données.png";
import img_gestion from "../../assets/icones/Icone_gestion_simplifiée.png";

export default function WebApp() {
  return (
    <div className="section-with-border">
      <section className="container">
        <div className="title-holder mb-4">
          <h2>Application Web de Gestion des Accès</h2>
          <p className="subtitle">
            Une interface intuitive pour gérer tous vos accès en un seul
            endroit.
          </p>
        </div>
        <div className="card">
          <div className="row d-flex align-items-center gap-5">
            <div className="col-md-5 d-flex justify-content-center">
              <img
                src={img}
                className="img-fluid"
                alt="Mockup de l'application web de gestion des accès"
              />
            </div>
            <div className="col-md-6">
              <div className="card-body bg-tertiary rounded">
                <p className="card-text">
                  Notre <span>application web</span> vous offre une solution
                  complète pour la <span>gestion centralisée des accès</span>.
                </p>
                <p className="card-text">
                  Configurez facilement les{" "}
                  <span>accès de vos résidents et maintenanciers</span>,
                  surveillez les <span>activités en temps réel</span>, et
                  générez des <span>rapports détaillés</span>.
                </p>
                <p className="card-text">
                  Grâce à une interface <span>intuitive et conviviale</span>,
                  vous bénéficiez d'un contrôle total sur votre système de
                  gestion des accès, tout en simplifiant les opérations
                  quotidiennes.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex flex-column flex-md-row justify-content-around mt-5">
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_securite}
              alt="Sécurité des données de l'application web"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Sécurité des données</p>
          </div>
          <div className="d-flex flex-column align-items-center gap-3">
            <img
              src={img_gestion}
              alt="Gestion simplifiée avec l'application web"
              style={{ width: "100px", height: "100px" }}
            />
            <p className="fw-bold">Gestion simplifiée</p>
          </div>
        </div>
      </section>
    </div>
  );
}
