import React from "react";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useLocation } from "react-router-dom";
import "../css/animations.css"; // Assurez-vous que vous avez les styles d'animation ici

const AnimatedRoutes = React.forwardRef((props, ref) => {
  const location = useLocation();

  return (
    <TransitionGroup ref={ref}>
      <CSSTransition key={location.key} classNames="fade" timeout={300}>
        <div className="transition-wrapper">{props.children}</div>
      </CSSTransition>
    </TransitionGroup>
  );
});

export default AnimatedRoutes;
